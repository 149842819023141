<template>
  <button
    type="button"
    class="default-button-height relative flex w-full flex-row items-center justify-center gap-x-1 overflow-hidden rounded-md border px-4 py-3 text-base font-semibold leading-5 lg:gap-x-3"
    :class="[baseClasses, $attrs.class]"
    @click="handlerDownload"
  >
    <template v-if="isButtonDownload">
      <template v-if="showIcon">
        <Icon
          v-if="!loading"
          name="icon-download"
          class="z-index h-4 w-full min-w-4 max-w-4"
        />
        <Icon v-else name="Close" class="z-index h-4 w-full min-w-4 max-w-4" />
      </template>
      <span v-if="showLabel" class="z-index" :class="textClasses">
        {{ buttonText }}
      </span>
      <span
        class="progress-bar-container"
        :class="backgroundColorDownload"
        :style="progressBarStyles || progressBarDownloadingStyles"
      />
    </template>
    <NavigationLink v-else :url="url" :class="textClasses">
      {{ buttonText }}
    </NavigationLink>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Icon from '~/components/common/Icon.vue'
import NavigationLink from '~/components/shared/navigation/Link.vue'
import { useDownloadReport } from '~/composables/useDownloadReport'
import { useButtonClassesAndEvent } from '~/composables/useButtonClassesAndEvent'
import type { TDownloadFile } from '~/types/benchmark-app.type'
import type { TCommon } from '~/src/types/common'

const props = withDefaults(
  defineProps<{
    text?: string
    showIcon?: boolean
    downloadFile: TDownloadFile
    variant: string
    showLabel?: boolean
    textClasses?: string
    isDownloading?: boolean
    progressBarStyles?: TCommon | null
  }>(),
  {
    text: 'Download',
    showIcon: true,
    showLabel: true,
    textClasses: '',
    isDownloading: false,
    progressBarStyles: null,
  },
)

const emit = defineEmits(['cancelDownload'])

const { baseClasses, backgroundColorDownload } = useButtonClassesAndEvent(
  props.variant,
)
const {
  downloading,
  cancelDownload,
  downloadReport,
  downloadVideoVimeo,
  progressBarDownloadingStyles,
} = useDownloadReport()

const loading = computed(() => downloading.value || props.isDownloading)

const buttonText = computed(() => {
  return loading.value ? 'Cancel' : props.text
})
const downloadCategory = computed(() => props.downloadFile?.category)
const isButtonDownload = computed(() => !props.downloadFile?.url)
const url = computed(() => props.downloadFile?.url || '')

const handlerDownload = () => {
  if (!isButtonDownload.value) return

  if (loading.value) {
    emit('cancelDownload')
    return cancelDownload()
  }

  if (props.downloadFile?.isVideoVimeo) {
    return downloadVideoVimeo(
      props.downloadFile?.vimeoId,
      props.downloadFile?.vimeoAuthToken,
    )
  }

  downloadReport(props.downloadFile, downloadCategory.value)
}
</script>

<style lang="scss" scoped>
.progress-bar-container {
  position: absolute;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: 0;
}
.z-index {
  z-index: 2;
}
</style>
